import { Box, Button, Container, Grid, GridItem, HStack, LinkOverlay, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import ReactRotatingText from 'react-rotating-text';

import FeatureRow from '../components/FeatureRow';

import Hero from '../images/HeroBG.png';
import Logo from '../images/LogoFinal.svg';
import NftGraphic from '../images/NFT_EX.png';
import NFT01 from '../images/nftex/CSNFT_013.png';
import NFT02 from '../images/nftex/CSNFT_011.png';
import NFT03 from '../images/nftex/CSNFT_023.png';
import NFT04 from '../images/nftex/CSNFT_024.png';
import NFT05 from '../images/nftex/CSNFT_027.png';
import NFT06 from '../images/nftex/CSNFT_031.png';

import FEAT01 from '../images/nftfeat/CSNFT_001.png';
import FEAT02 from '../images/nftfeat/CSNFT_005.png';
import FEAT03 from '../images/nftfeat/CSNFT_027.png';
import FEAT04 from '../images/nftfeat/CSNFT_031.png';
import { Helmet } from 'react-helmet';


const Index = () => {
  const CheesySnackUrl = "https://opensea.io/collection/cheesysnacks";

  return (
    <Box>
      <Helmet>
        <title>Cheesy Snacks - Cheese Flavored NFTs - Home</title>
      </Helmet>
      <SimpleGrid columns={{ sm: 1, md: 2}} bgImage={`${Hero}`} bgSize="cover" bgRepeat="no-repeat" minH={{md: "300", lg:"500"}} p={{base: 5, lg:0}} bgPos={{base: "left", lg:"bottom"}}>
        <Box pos="relative" display="flex" alignItems="center" justifyContent={{base: "center", lg:"flex-end"}}>
          <Box>
            <img src={Logo} alt="Cheesy Snacks" />
            <a href="https://opensea.io/collection/cheesysnacks">
              <Button ml="18%" colorScheme="primary-orange">Buy Now on OpenSea</Button>
            </a>
          </Box>
        </Box>
        <Box pos="relative" display="flex" alignItems="center">
          <img src={NftGraphic} alt="Cheesy Snack NFT Graphic" />
        </Box>
      </SimpleGrid>
      <Box>
        <Container maxW="container.lg">
          <Box width="100%" minH={{base: "125px", md: "auto"}} my={20}>
            <Text fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" display="inline" fontSize={{base: "40", lg:"64"}} fontWeight="bold">Cheesy Snacks are </Text>
            <Text color="primary-orange.500" display="inline" fontSize={{base: "40", lg:"64"}} fontWeight="black">
              <ReactRotatingText cursor={false} items={["SNAX","Corn","Cheese","Not Food","NFTs","Blockchain","Flavored","Ephemera","Salty","Savory","Pizza?","Edible"]} />
            </Text>
          </Box>
        </Container>
        <Container maxW="container.lg">
          <SimpleGrid width="100%" gap={8} columns={{sm:1, lg:2}}>
            <Box mt={6}>
              <Text fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" fontSize={36} fontWeight="bold" color="primary-orange.500">About the Drop</Text>
              <Text mt={-2} casing="uppercase" fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" fontWeight="600">It's Snack Time</Text>
              <Text mt={4} fontSize="14" lineHeight={2}>
                Cheesy Snacks are the first corn chip on the blockchain. If you're like us, whenever you eat a savory, cheesy snack, you wish it was a never ending bag. Now, you can have your corn chips and eat then too. Take solace in knowing that even if you eat all your snacks, a Cheesy Snack remains in perpetuity. 
              </Text>
              <Text mt={4} fontSize="14" lineHeight={2}>
                Each Cheesy Snack in this drop is a handpicked chip from a bag of corn chips produced by a  leading manufacturer of corn-based ephemera. Each was photographed, artfully rendered, and then promptly consumed by our Head of Supply Chain and Consumption. No being known to the universe can reproduce the specific Snack you purchase– ever. The technology simply does not exist for such a reconstruction, we've checked. 
              </Text>
              <Text mt={4} fontSize="14" lineHeight={2}>
              Cheesy Snacks will continue to produce high-quality non-fungibles from cheese flavored items in the future, released in limited numbers, but this will always be remembered in perpetuity as the original Cheesy Snack.
              </Text>
            </Box>
            <Box>
              <Box mt={24} bg="#FAFAFA" borderRadius={20} borderStyle="solid" borderWidth="1pt" borderColor="#F5F5F5" p={12} shadow="md">
                <Box mt={-24}>
                  <SimpleGrid gap={2} columns={3}>
                    <Box maxH={120}><img style={{borderRadius: 14, boxShadow:"0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto"}} src={NFT01}></img></Box>
                    <Box maxH={120}><img style={{borderRadius: 14, boxShadow:"0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto"}} src={NFT02}></img></Box>
                    <Box maxH={120}><img style={{borderRadius: 14, boxShadow:"0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto"}} src={NFT03}></img></Box>
                    <Box maxH={120}><img style={{borderRadius: 14, boxShadow:"0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto"}} src={NFT04}></img></Box>
                    <Box maxH={120}><img style={{borderRadius: 14, boxShadow:"0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto"}} src={NFT05}></img></Box>
                    <Box maxH={120}><img style={{borderRadius: 14, boxShadow:"0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto"}} src={NFT06}></img></Box>
                  </SimpleGrid>
                </Box>
                <Box mt={8}>
                  <Text fontSize={12}>A perfectly unique cheese flavored corn chip, hand selected from a bag produced by a leading cheesy corn chip manufacturer. Where did it come from? We're not sure. Our Head of Supply Chain and Consumption ate each chip and gave them a solid 9/10, but we had to wash his keyboard and mouse after with alcohol wipes...</Text>
                </Box>
                <Box display="flex" justifyContent="center" mt="6">
                  <a href={CheesySnackUrl}>
                    <Button colorScheme="primary-orange">Buy Now on OpenSea</Button>
                  </a>
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Container>
        <Container mt={24} maxW="container.md">
          <Box mb={{base: 24, lg: 12}}>
            <Text textAlign="center" fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" fontSize={36} fontWeight="bold" color="primary-orange.500">Types of Snacks</Text>
            <Text textAlign="center" mt={-2} casing="uppercase" fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" fontWeight="600">The Four Major Forms</Text>
          </Box>
          <VStack spacing={{base: 24, lg: 10}}>
            <FeatureRow title="Flapper" image={FEAT03} content="Folded over through manufacturing errors. A unique desireable trait that makes them one-of-a-kind. They add a unique additional crunch factor that contributes to a complex mouthfeel. Through our advanced deep-chip learning, we’ve established that they occur less than 10% of the time across all bags of our cheese-flavored corn-based chips." />
            <FeatureRow title="Gemstone" image={FEAT01} content="Premium snack that exudes brilliance, much like a diamond. While many start their life as a premire chip, due to the strain of packaging and stress of transport, few survive completely intact. This erosion in quality means that less than 33% of all chips in a bag end their life as unfettered as this gemstone." />
            <FeatureRow title="Compound Curl" image={FEAT04} content="The environment of the manufacturing exposes chips to a range of humidity and heat causing unique structural effects. The curl, while similar to the Flapper in its verticality, occurs not due to influences before cooking, but due to the cooking itself. Careful! In tests led by our Head of Supply Chain and Consumption, they can produce painful soft palette damage." />
            <FeatureRow title="Cleavage" image={FEAT02} content="This snack started its existence as a Gemstone. Perfect in its qualities. Due to the impact of manufacturing, packaging, and the supply chain, it recieved a crushing blow. Potentially due to a weakness in the underlying structure of the chip, a cleaved Cheesy Snack is broken in a beautiful and unique manner, typically along one point of it’s triangular form." />
          </VStack>
        </Container>
        <Container mt={24} maxW="container.md">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text textAlign="center" fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" fontSize={36} fontWeight="bold">Find a CheesySnack to Call Your Own</Text>
            <Text textAlign="center" mt={-2} casing="uppercase" fontFamily="'Avenir Next','Lato','Helvetica',sans-serif" fontWeight="600">More to come soon</Text>
            <a href={CheesySnackUrl}>
              <Button mt="5" colorScheme="primary-orange">Buy Your CheesySnack</Button>
            </a>
          </Box>
        </Container>
      </Box>
      <Box minH={120}>

      </Box>
    </Box>
  );
}

export default Index;
