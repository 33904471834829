import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const FeatureRow = ({ title, content, image }) => (
    <Box 
        minH={150} 
        position="relative" 
        display="flex" 
        flexDirection={{base: "column", lg: "row"}} 
        alignItems="center" bg="#FAFAFA" borderRadius={20} 
        borderStyle="solid" borderWidth="1pt" 
        borderColor="#F5F5F5" p={4} shadow="md"
    >
        <Box
        h={{ base: "100px", lg: "100px" }}
        w={{ base: "100px", lg: "100px" }}
        position="absolute"
        left={{base: "10px", lg:"-50px"}}
        top={{base: "-50px", lg: "auto"}}>
            <img height={{ sm: "100%", lg: "100px" }} width={{ sm: "100", lg: "100px" }} src={image} style={{ borderRadius: 14, boxShadow: "0px 3px 8px rgba(0,0,0,0.15)", maxHeight: "100%", margin: "auto" }}></img>
        </Box>
        <Box pl={{ sm: 0, lg: 50 }} pt={{base: 50, lg: 0}}>
            <Text fontSize={20} fontWeight="bold" fontFamily="'Avenir Next','Lato','Helvetica',sans-serif">
                {title}
            </Text>
            <Text fontSize={12}>
                {content}
            </Text>
        </Box>
    </Box>
)

export default FeatureRow;
